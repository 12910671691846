<template>
  <div>
    <b-card no-body>
      <!-- SINGLE DATA -->
      <b-modal
        ref="singleDataModal"
        centered
        :title="'Fichier: ' + singleData.slug"
        ok-only
        ok-title="Fermer"
      >
        <b-card-text>
          Nom:<b> {{ singleData.name }}</b>
          <hr />
        </b-card-text>
        <b-card-text>
          Commune:<b> {{ singleData.commune }}</b>
          <hr />
        </b-card-text>
        <b-card-text>
          Adresse:<b> {{ singleData.address }}</b>
          <hr />
        </b-card-text>
        <b-card-text>
          Téléphone:<b> {{ singleData.contact }}</b>
          <hr />
        </b-card-text>
        <b-card-text>
          Adresse e-mail:<b> {{ singleData.email }}</b>
          <hr />
        </b-card-text>
        <b-card-text v-if="singleData.updatedAt">
          Derniere mise à jour:<b>
            {{ this.$coddyger.cdgDateFormat(singleData.updatedAt, true) }}</b
          >
          <hr />
        </b-card-text>
        <b-card-text v-if="singleData.createdAt">
          Date de création:<b>
            {{ this.$coddyger.cdgDateFormat(singleData.createdAt) }}</b
          >
          <hr />
        </b-card-text>
      </b-modal>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="tabledata.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tabledata.pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button class="mr-1" variant="primary" :to="{name: 'apps-medical-centre-save'}">
              Nouveau
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" offset-md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="tabledata.filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        id="datatable"
        :items="buildData"
        responsive
        :fields="tabledata.columns"
        primary-key="id"
        :current-page="tabledata.currentPage"
        :per-page="tabledata.perPage"
        :filter="tabledata.filter"
        show-empty
        empty-text="Auncune donnée disponible"
        class="position-relative"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`post-row-${data.item.slug}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mr-1"
              @click.stop="openDialog(data.item)"
            />

            <b-link :to="{name: 'apps-medical-centre-edit', params: {slug: data.item.slug}}">
              <feather-icon
              :id="`post-row-${data.item.slug}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mr-1"
              
            />
            </b-link>
            

            <feather-icon
              :id="`post-row-${data.item.slug}-trash-icon`"
              icon="TrashIcon"
              size="16"
              class=""
              @click.stop="deleteRecordPrompt(data.item.slug)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Total: {{ buildData.length }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="tabledata.currentPage"
              :total-rows="tabledata.totalRows"
              :per-page="tabledata.perPage"
              
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="datatable"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BTooltip,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BFormTags,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import ModuleCentre from "@/store/modules/medical/centre/centre.module";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import * as payloads from './payloads'

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BFormTags,
    BListGroup,
    BListGroupItem,

    vSelect,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  data() {
    return {
      isLoading: false,
      singleData: [], // For single view mode
      locale: "fr",
      isModalActive: false,
      modalData: [],
      // TABLE PARAMS
      tabledata: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, 30],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        columns: [
          {
            key: "name",
            label: "Titre",
            sortable: true,
            formatter: (val) => `${val}`,
          },
          {
            key: "commune",
            label: "Commune",
            sortable: true,
            formatter: (val) => `${val !== null ? `${this.$coddyger.capitalizeFirstLetter(val.toLowerCase())}` : ""}`,
          },
          { key: "actions" },
        ],
        searchTerm: "",
      },
      // Data NEW/EDIT Modal
      formdata: {
        slug: null,
        //-FORM-PROPERTIES-
        title: "",
        content: "",
        category: "",
        file: null,
        categories: payloads.category,
        options: {
          date: {
            date: true,
            delimiter: "-",
            datePattern: ["d", "m", "Y"],
          },
        },
      },
      // MODAL TOOLS
      modaldata: {
        data: [],
        isModalActive: false,
      }
    };
  },
  created() {
    if (!ModuleCentre.isRegistered) {
      this.$store.registerModule("ModuleCentre", ModuleCentre);
      ModuleCentre.isRegistered = true;
    }
    this.$store
      .dispatch("ModuleCentre/fetchCentre")
      .then((data) => {})
      .catch((e) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Erreur`,
            icon: "WarningIcon",
            variant: "danger",
            text: `Impossible de charger la liste des données. Merci de réessayer plutard.`,
          },
        });
    });
  },
  computed: {
    buildData() {
      const data = this.$store.state.ModuleCentre.data;
      this.tabledata.totalRows = data === undefined ? 0 : data.length;

      return data === undefined ? [] : data;
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    isModalActive() {
      console.log('enterring watch')
      this.resetValues();
    },
  },

  methods: {
    buildCurrentPath() {
      let path = this.$router.history.current.path;
      let getLastItem = (thePath) =>
        thePath.substring(thePath.lastIndexOf("/") + 1);
      path = getLastItem(path);

      return {
        path: path,
        route: `apps-post-${path}`
      };
    },
    emptyFileInput() {
      this.formdata.file = null;
    },
    closeModal(object) {
      if (object === "save") {
        this.$refs["setDataModal"].hide();
        this.resetValues();
      } else if (object === "edit") {
        this.$refs["editModal"].hide();
        this.resetValues();
      } else {
        this.$refs["importModal"].hide();
      }
    },
    openSaveDialog() {
      this.$refs["setDataModal"].show();
    },
    openImportDialog() {
      this.$refs["importModal"].show();
    },
    swalError(title, message, type) {
      this.$swal({
        title: title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openDialog(data) {
      this.$refs.singleDataModal.show();
      this.singleData = data;
    },

    /* ---------------------
    | RECORD REMOVAL HANDLER
    */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { pid: id }),
      });
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Voulez-vous supprimer cet enregistrement ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-danger",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteData();
          } else {
            this.revertUrl();
          }
        });
    },
    deleteData() {
      let id = this.$route.query.pid;
      if (id !== "" || id !== null) {
        this.$store
          .dispatch("ModuleCentre/remove", {
            item: id,
            path: this.buildCurrentPath().path,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Action Réussie`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Enregistrement supprimé avec succès!`,
              },
            });
            this.revertUrl();
          })
          .catch((error) => {
            if (typeof error === "object") {
              for (let err in error) {
                this.swalError("Erreur", err, "error");
              }
            } else {
              this.$vs.notify({
                title: "Erreur",
                text: error,
                color: "danger",
                icon: "error",
              });
            }
          });
      } else {
      }
    },
    revertUrl() {
      console.log(this.buildCurrentPath().route)
      this.$router.replace({ name: this.buildCurrentPath().route });
    },
    // END ---
    // MODAL FORM TOOL --------------
    resetValues() {
      this.formdata.slug = null;
      this.formdata.title = "";
      this.formdata.category = [];
      this.formdata.content = '';
      this.formdata.file = null;
      this.modaldata.data = {};

      console.log('INPUT RESET SUCCEED...')
    },
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.title === "") {
        this.swalError("Erreur", this.buildRequiredMessage("titre"), "error");
      } else if (this.formdata.category === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Categorie"),
          "error"
        );
      } else {
        let global = {};
        let formData = new FormData();

        let collectedUserSlug = [];
        for(let x in this.formdata.users) {
          let user = this.formdata.users[x]

          collectedUserSlug.push(user.slug)
        }

        formData.append("slug", this.formdata.slug);
        formData.append("title", this.formdata.title);
        formData.append("category", this.formdata.category.key);
        formData.append("content", this.formdata.content);
        formData.append("file", this.formdata.file);

        global = {
          formdata: formData,
          path: this.buildCurrentPath().path,
        };

        this.isLoading = true;

        if (this.formdata.slug !== null && this.formdata.slug !== "") {
          this.$store
            .dispatch("ModuleCentre/edit", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Modification effectué avec succès!`,
                },
              });
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
        } else {
          this.$store
            .dispatch("ModuleCentre/save", global)
            .then(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Enregistrement modifié avec succès!`,
                },
              });
              this.resetValues();
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
        }
      }
    },
    importer(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.file === "" || this.formdata.file === null) {
        this.swalError(
          "Erreur",
          "Veuillez sélectionner le fichier à importer",
          "error"
        );
      } else {
        let global = {};

        let formData = new FormData();

        formData.append("file", this.formdata.file);
        
        global = {
          formdata: formData,
          path: this.buildCurrentPath().path,
        };
        this.isLoading = true;
        this.$store
          .dispatch("ModuleCentre/importItem", global)
          .then((doc) => {
            console.log(doc)
            this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Fichier importé avec succès! ${doc.success} lignes importées`,
                },
              });
            
            this.formdata.file = null;
            this.isLoading = false;
            this.$router.go()
          })
          .catch((error) => {
            let status = error.status;
            let message = error.message;
            let data = error.data;

            if (status === 422) {
              this.swalError("Erreur", message, "error");
            } else {
              this.swalError("Erreur", message, "error");
            }

            this.isLoading = false;
          });
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`;
    },
    buildEditModal(data) {
      this.modaldata.data = data;
      this.modaldata.isModalActive = true;

      if (!data) return;
      if (Object.entries(data).length === 0) {
        this.resetValues();
      } else {
        const { slug, title, category, categoryName, content } = JSON.parse(
          JSON.stringify(data)
        );
        
        this.formdata.slug = slug;
        this.formdata.title = title;
        this.formdata.category = {
          key: category,
          text: categoryName
        };
        this.formdata.content = content;
      }

      this.$refs['setDataModal'].show()
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
      appDesc,
    };
  },
};
</script>
